import ContainImage from '@components/views/ContainImage';
import Container from '@components/containers/Container';
import Heading, { HeadingProps } from '../Heading';
import Image from 'next/image';
import React from 'react';
import clsx from 'clsx';
import { IButtonACF } from 'src/types/IButtonACF';
import { useColors } from 'src/context/colors.context';
import ButtonACF from '@components/containers/ButtonACF';

export interface CtaBannerProps {
  heading?: HeadingProps;
  image?: string;
  description?: string;
  buttons: Array<IButtonACF>;
  width?: 'full' | 'wide';
  fitImage?: boolean;
  sectionLabel?: string;
  background: 'primary' | 'dark' | 'light';
  adjustImage?: boolean;
  defaultDecorator?: boolean;
  shadowImage?: boolean;
}

/**
 *
 * @link https://www.figma.com/file/bEoq4a8N0Yy6hvVvwAQxiS/WSC-%3A%3A-UI-Design?node-id=551%3A12355
 */
const CtaBanner: React.FunctionComponent<CtaBannerProps> = (props) => {
  const {
    heading,
    buttons,
    image,
    description,
    fitImage,
    width,
    background,
    sectionLabel,
    adjustImage,
    defaultDecorator,
    shadowImage,
  } = props;

  const { productColors } = useColors();

  return (
    <section
      className={clsx('mx-auto relative', {
        'bg-neutral-1': background === 'primary' || !background,
        'bg-neutral-0': background === 'light',
        'bg-neutral-9': background === 'dark',
        'max-w-[130.4rem]': width === 'wide',
        'my-40': adjustImage,
        'mt-40 lg:mt-100 mb-0': !adjustImage,
      })}
    >
      <Container className={clsx({ '!static': defaultDecorator })}>
        <div className="grid lg:grid-cols-2 items-center gap-32 lg:gap-48 py-24 lg:py-0">
          {image && (
            <div
              className={clsx('hidden lg:flex', {
                'lg:-mt-70 items-end mt-auto': !adjustImage,
                'shadow-[48px_48px_104px_-24px_rgba(19,26,32,0.08)]':
                  shadowImage,
              })}
            >
              {fitImage ? (
                <ContainImage src={image} />
              ) : (
                <Image src={image} width={462} height={415} alt="" />
              )}
            </div>
          )}
          <div className="py-24">
            {!!sectionLabel && (
              <div
                className={clsx('mb-8', {
                  'text-neutral-3': background === 'dark',
                  'text-neutral-5': background !== 'dark',
                })}
              >
                {sectionLabel}
              </div>
            )}
            {heading && (
              <div
                className={clsx('text-4xl font-extrabold', {
                  'text-neutral-0': background === 'dark',
                })}
              >
                <Heading {...heading} />
              </div>
            )}
            {description && (
              <p
                className="text-neutral-4 pt-16"
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            )}
            {buttons?.length > 0 && (
              <div className="flex flex-wrap gap-32 pt-40">
                {buttons.map((button, index) => (
                  <div key={index}>
                    <ButtonACF {...button} />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        {defaultDecorator && (
          <div
            className="w-100 h-100 bg-neutral-1 absolute bottom-0 right-0"
            style={{ backgroundColor: productColors?.washed || '' }}
          />
        )}
      </Container>
    </section>
  );
};

export default CtaBanner;
